// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    callback: `${ROOTS.AUTH}/callback`,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    customers: `${ROOTS.DASHBOARD}/customers`,
    customerProfile: (id: string) => `${ROOTS.DASHBOARD}/customer/${id}`,
    invoices: `${ROOTS.DASHBOARD}/invoices`,
    invoiceOverview: (id: string, customerId: string) =>
      `${ROOTS.DASHBOARD}/invoice/${id}?customerId=${customerId}`,
    payments: `${ROOTS.DASHBOARD}/payments`,
    paymentMatching: `${ROOTS.DASHBOARD}/payment-matching`,
    // imports: `${ROOTS.DASHBOARD}/imports`,
    // failures: `${ROOTS.DASHBOARD}/failures`,
    // analytics: `${ROOTS.DASHBOARD}/analytics`,
    // reports: `${ROOTS.DASHBOARD}/reports`,
    users: `${ROOTS.DASHBOARD}/users`,
    // profile: `${ROOTS.DASHBOARD}/profile`,
    // security: `${ROOTS.DASHBOARD}/security`,
    // settings: `${ROOTS.DASHBOARD}/settings`,
    clients: `${ROOTS.DASHBOARD}/clients`,
    webhooks: {
      subscriptions: `${ROOTS.DASHBOARD}/webhooks`,
      history: `${ROOTS.DASHBOARD}/webhooks/history`,
    },
  },
};
