import { createApi } from "@reduxjs/toolkit/query/react";

import { authQuery } from "./baseQuery";
import type { TableResponse } from "src/types/common";
import type { IGridCustomer, ICustomerProfile } from "src/types/customers";

export const customersAPISlice = createApi({
  reducerPath: "presentation-service-customers-api",
  tagTypes: ["Customers"],
  baseQuery: authQuery,
  endpoints: (builder) => ({

    getCustomers: builder.query<TableResponse<IGridCustomer>, void>({
      query: () => "/customers?page=1&limit=5000",
    }),

    getCustomer: builder.query<ICustomerProfile, string>({
      query: (id) => `/customers/${id}`,
      transformResponse: (res: ICustomerProfile) => {
        res.invoices = res.invoices.map((x: any) => ({
          ...x,
          status: x.status,
          stage: x.stage,
          paymentStatus: x.paymentStatus,
          totalRemainingBalance: parseFloat(x.totalRemainingBalance),
          totalAmount: parseFloat(x.totalAmount),
        }));

        return res;
      },
    }),
  }),
});

export const {
  useGetCustomerQuery,
  useGetCustomersQuery,
  useLazyGetCustomersQuery,
} = customersAPISlice;
