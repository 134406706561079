import type { RouteObject } from 'react-router';

import { Outlet } from 'react-router';
import { lazy, Suspense } from 'react';

import { SplashScreen } from 'src/components/loading-screen';

import { GuestGuard } from 'src/auth/guard';

// ----------------------------------------------------------------------

const Auth = {
  CallbackPage: lazy(() => import('src/pages/auth/PageAuthCallback')),
};

// ----------------------------------------------------------------------

export const authRoutes: RouteObject[] = [
  {
    path: 'auth',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: 'callback',
        element: (
          <GuestGuard>
            <Auth.CallbackPage />
          </GuestGuard>
        ),
      },
    ],
  },
];
