// ----------------------------------------------------------------------

export const fallbackLng = 'en';
export const languages = ['en', 'se', 'fr', 'de'];

export type LanguageValue = (typeof languages)[number];

// ----------------------------------------------------------------------

export function i18nOptions(lng = fallbackLng) {
  return {
    // debug: true,
    lng,
    fallbackLng,
    supportedLngs: languages,
  };
}

// ----------------------------------------------------------------------

export const changeLangMessages: Record<
  LanguageValue,
  { success: string; error: string; loading: string }
> = {
  en: {
    success: 'Language has been changed!',
    error: 'Error changing language!',
    loading: 'Loading...',
  },
  se: {
    success: 'Language has been changed!',
    error: 'Error changing language!',
    loading: 'Loading...',
  },
  fr: {
    success: 'La langue a été changée!',
    error: 'Erreur lors du changement de langue!',
    loading: 'Chargement...',
  },
  de: {
    success: 'DE Language has been changed! DE',
    error: 'DE Error changing language! DE',
    loading: 'DE Loading... DE',
  },
};
