import { Box, CircularProgress } from '@mui/material';

export function FixedCenterCircularProgress({
  color = 'primary',
}: {
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit';
}) {
  return (
    <Box
      justifySelf="center"
      textAlign="center"
      sx={{
        // position: 'fixed',
        // top: '50%',
        // left: '50%',
        // transform: 'translate(-50%, -50%)',
        zIndex: 9999,
      }}
    >
      <CircularProgress color={color} />
    </Box>
  );
}
