import { createApi } from "@reduxjs/toolkit/query/react";

import type { IClient } from "src/types/clients";
import { authQuery } from "./baseQuery";

export const clientsAPISlice = createApi({
  reducerPath: "presentation-service-clients-api",
  tagTypes: ["Clients"],
  baseQuery: authQuery,
  endpoints: (builder) => ({

    getClients: builder.query<{ data: IClient[] }, void | string[]>({
      query: (attributes) => `/clients${attributes ? `?attributes=${attributes.join(",")}` : ""}`,
      providesTags: ["Clients"],
    }),

    getClient: builder.query<{ data: IClient }, string>({
      query: (clientId) => `/clients/${clientId}`,
      providesTags: ["Clients"],
    }),

    addClient: builder.mutation<{ id: string; slug: string; readableId: string; name: string }, void>({
      query: (body) => ({
        url: "/clients",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Clients"],
    }),

    updateClient: builder.mutation<string, { id: string; body: Partial<IClient> }>({
      query: ({ id, body }) => ({
        url: `/clients/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Clients"],
    }),

    deleteClient: builder.mutation({
      query: (id) => ({
        url: `/clients/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Clients"],
    }),
  }),
});

export const {
  useGetClientsQuery,
  useAddClientMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,
  useGetClientQuery,
} = clientsAPISlice;
