import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { clientsAPISlice } from "./slices/clientsAPISlice";
import { usersAPISlice } from "./slices/usersAPISlice";
import { customersAPISlice } from "./slices/customersAPISlice";
import { invoicesAPISlice } from "./slices/invoicesAPISlice";
import { paymentsAPISlice } from "./slices/paymentsAPISlice";
import { webhooksAPISlice } from "./slices/webhooksAPISlice";

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false, }).concat(
      clientsAPISlice.middleware,
      usersAPISlice.middleware,
      customersAPISlice.middleware,
      invoicesAPISlice.middleware,
      paymentsAPISlice.middleware,
      webhooksAPISlice.middleware,
    ),
  reducer: {
    [clientsAPISlice.reducerPath]: clientsAPISlice.reducer,
    [usersAPISlice.reducerPath]: usersAPISlice.reducer,
    [customersAPISlice.reducerPath]: customersAPISlice.reducer,
    [invoicesAPISlice.reducerPath]: invoicesAPISlice.reducer,
    [paymentsAPISlice.reducerPath]: paymentsAPISlice.reducer,
    [webhooksAPISlice.reducerPath]: webhooksAPISlice.reducer,
  },
});

setupListeners(store.dispatch);

export type IState = ReturnType<typeof store.getState>;

export type RootState = ReturnType<typeof store.getState>;
