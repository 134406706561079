import { paths } from 'src/routes/paths';

import packageJson from '../package.json';

export type ConfigValue = {
  appName: string;
  appVersion: string;
  assetsDir: string;
  auth: {
    redirectPath: string;
  };
  debounceTime: number;
  WORKSPACE_LOCAL_STORAGE_KEY: string;
};

export const CONFIG: ConfigValue = {
  appName: 'Inio Back Office Portal',
  appVersion: packageJson.version,
  assetsDir: import.meta.env.VITE_ASSETS_DIR ?? '',
  auth: {
    redirectPath: paths.dashboard.root,
  },
  debounceTime: 500,
  WORKSPACE_LOCAL_STORAGE_KEY: "workspace",
};
