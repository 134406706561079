import type { Dayjs } from 'dayjs';

declare module 'react-oidc-context' {
  // Extends profile props, these come from Keycloak client scopes
  interface AuthContextProps {
    user: AuthState['user'] & {
      profile: {
        clientId: string;
        roles: UserRoles[];
      };
    };
  }
}

export type IDateValue = string | number | null;

export type IDatePickerControl = Dayjs | null;

export enum UserRoles {
  CLIENT_MANAGER = 'client-manager',
  CLIENT_OPERATOR = 'client-operator',
  CUSTOMER_OPERATOR = 'customer-operator',
  KEY_ACCOUNT_MANAGER = 'key-account-manager',
}

export type TableResponse<TData> = {
  rows: TData[];
  totalItems: number;
  page: number;
  limit: number;
  totalPages: number;
};

export type TableDataResponse<TData> = {
  data: TData[];
  totalItems: number;
};

export type DataResponse<TData> = {
  data: TData;
  status: number;
};

export interface ISelectOption {
  key: string;
  value: string;
}
