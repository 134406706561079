import { createApi } from '@reduxjs/toolkit/query/react';

import { authQuery } from "./baseQuery";
import type { TableResponse } from "src/types/common";
import { IPayment, PaymentStatus } from "src/types/payments";

export const paymentsAPISlice = createApi({
  reducerPath: 'presentation-service-payments-api',
  tagTypes: ['Payments'],
  baseQuery: authQuery,
  endpoints: (builder) => ({
    getAllPayments: builder.query<TableResponse<IPayment>, void>({
      query: () => "/payments?page=1&limit=5000",
      transformResponse: (res: TableResponse<IPayment>) => {
        res.rows = res.rows.map((x: any) => {
          return {
            ...x,
            customerSortProp: x.customer?.customerName || x.rawFormat?.debitorName || ""
          }
        });

        return res;
      },
      providesTags: ["Payments"],
    }),

    // getPaymentsByIds: builder.query<TableResponse<IPayment>, string[]>({
    //   query: (ids: string[]) => ({
    //     url: "/payments/get-payments-by-ids",
    //     method: "POST",
    //     body: { ids },
    //   }),
    //   providesTags: ["Payments"],
    // }),

    getPaymentById: builder.query<TableResponse<IPayment>, string>({
      query: (id) => ({
        url: "/payments/get-payments-by-ids",
        method: "POST",
        body: { ids: [id] },
      }),
      providesTags: ["Payments"],
    }),

    updateStatusPayment: builder.mutation({
      query: (requestData) => ({
        url: "/payments/update-status",
        method: "POST",
        body: requestData,
      }),
      async onQueryStarted(requestData, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          dispatch(
            paymentsAPISlice.util.updateQueryData('getAllPayments', undefined, (allPaymentsDraft) => {
              requestData.payments.forEach((x: any) => {
                const payment = allPaymentsDraft.rows.find((p) => p.id === x.id);

                if (payment) {
                  payment.status = x.status;
                }
              })
            }),
          )

          requestData.payments.forEach((x: any) => {
            dispatch(
              paymentsAPISlice.util.updateQueryData('getPaymentById', x.id, (singlePaymentDraft) => {
                const payment = singlePaymentDraft.rows.find((p) => p.id === x.id);

                if (payment) {
                  payment.status = x.status;
                }
              }),
            )
          });
        } catch {
          console.log("Error updating payments!")
        }
      },
      // invalidatesTags: ["Payments"],
    }),

    matchPayment: builder.mutation({
      query: (requestData) => ({
        url: "/payments/match-payment",
        method: "POST",
        body: requestData,
      }),
      async onQueryStarted(requestData, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          dispatch(
            paymentsAPISlice.util.updateQueryData('getAllPayments', undefined, (allPaymentsDraft) => {

              const payment = allPaymentsDraft.rows.find((p) => p.id === requestData.paymentId);

              if (payment) {
                payment.status = PaymentStatus.MatchedManual;
              }
            }),
          )

          dispatch(
            paymentsAPISlice.util.updateQueryData('getPaymentById', requestData.paymentId, (singlePaymentDraft) => {

              const payment = singlePaymentDraft.rows.find((p) => p.id === requestData.paymentId);

              if (payment) {
                payment.status = PaymentStatus.MatchedManual;
              }
            }),
          )
        } catch {
          console.log("Error updating payment!")
        }
      },
      // invalidatesTags: ["Payments"],
    }),
  }),
});

export const {
  useGetAllPaymentsQuery,
  // useLazyGetPaymentsByIdsQuery,
  useLazyGetPaymentByIdQuery,
  useUpdateStatusPaymentMutation,
  useMatchPaymentMutation,
} = paymentsAPISlice;
