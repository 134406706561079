import type { LinkProps } from '@mui/material/Link';

import { forwardRef } from 'react';
import { mergeClasses } from 'minimal-shared/utils';

import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';

import { RouterLink } from 'src/routes/components';

import { logoClasses } from './classes';

// ----------------------------------------------------------------------

export type LogoProps = LinkProps & {
  isSingle?: boolean;
  disabled?: boolean;
};

export const Logo = forwardRef<HTMLAnchorElement, LogoProps>((props, ref) => {
  const { className, href = '/', isSingle = true, disabled, sx, ...other } = props;

  const singleLogo = (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6135_120144)">
        <path
          d="M47.3846 35.955C61.1669 35.9881 72.3664 24.8423 72.3995 11.06C72.3996 11.04 72.3996 11.02 72.3996 11H47.3846V35.955Z"
          fill="black"
        />
        <path
          d="M47.3846 60.9197V109.777H72.3996V35.9694C58.6174 35.9363 47.4178 47.0801 47.3847 60.8597C47.3847 60.8797 47.3846 60.8997 47.3846 60.9197Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_6135_120144">
          <rect width="25" height="99" fill="white" transform="translate(47.5 11)" />
        </clipPath>
      </defs>
    </svg>
  );

  const fullLogo = (
    <svg
      width="232"
      height="100"
      viewBox="0 0 232 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6135_120135)">
        <path d="M145.9 24.9786H120.885V98.7775H145.9V24.9786Z" fill="black" />
        <path
          d="M83.5932 23.7325C74.1298 23.7325 66.3382 26.5716 60.4393 34.3V24.9786H35.4244V98.7775H60.4236V58.4475C60.4236 49.9304 65.1553 43.7792 74.0982 43.7792C83.0412 43.7792 85.5963 50.2459 85.5963 58.1478V98.7775H110.454V51.35C110.454 43.3061 109.681 37.4703 103.924 31.461C98.3403 25.783 91.3532 23.7325 83.5932 23.7325Z"
          fill="black"
        />
        <path
          d="M0 24.955C13.7822 24.9881 24.9818 13.8423 25.0149 0.06002C25.0149 0.04001 25.015 0.02 25.015 0H0V24.955Z"
          fill="black"
        />
        <path
          d="M0 49.9197V98.7775H25.015V24.9694C11.2327 24.9363 0.03318 36.0801 7e-05 49.8597C2e-05 49.8797 0 49.8997 0 49.9197Z"
          fill="black"
        />
        <path
          d="M153.651 61.7829C153.651 37.5541 172.254 23.7091 192.589 23.7091C212.781 23.7091 231.385 37.5541 231.385 61.7829C231.385 86.0117 212.781 100 192.589 100C172.254 100 153.651 86.0117 153.651 61.7829ZM207.472 61.7895C207.472 52.4347 202.562 44.7165 192.584 44.7165C182.459 44.7165 177.566 52.4347 177.566 61.7895C177.566 71.142 182.576 79.042 192.584 79.042C202.447 79.042 207.472 71.142 207.472 61.7895Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_6135_120135">
          <rect width="231.385" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  return (
    <LogoRoot
      ref={ref}
      component={RouterLink}
      href={href}
      aria-label="Logo"
      underline="none"
      className={mergeClasses([logoClasses.root, className])}
      sx={[
        () => ({
          ...(!isSingle
            ? {
                width: 224,
                height: 56.9,
                padding: '2.222px 20.273px 3.115px 20.444px',
              }
            : {}),
          ...(disabled && { pointerEvents: 'none' }),
          '& svg': {
            ...(!isSingle
              ? {
                  width: 119.28,
                  height: 51.55,
                }
              : {
                  width: 40,
                  height: 33,
                }),
          },
          justifyContent: isSingle ? 'center' : 'flex-start',
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...other}
    >
      {isSingle ? singleLogo : fullLogo}
    </LogoRoot>
  );
});

// ----------------------------------------------------------------------

const LogoRoot = styled(Link)(() => ({
  flexShrink: 0,
  color: 'transparent',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
}));
