import { useState, useEffect } from 'react';

import { SplashScreen } from 'src/components/loading-screen';

import { useAuthContext } from '../hooks';
import { useLocation } from 'react-router';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: React.ReactNode;
};

export function AuthGuard({ children }: AuthGuardProps) {
  const { pathname, search } = useLocation();

  const { isAuthenticated, isLoading, signinRedirect, user } = useAuthContext();

  const [isChecking, setIsChecking] = useState<boolean>(true);

  const checkPermissions = async (): Promise<void> => {
    if (isLoading) {
      return;
    }

    if (!isAuthenticated) {
      signinRedirect({
        redirect_uri: `${import.meta.env.VITE_KEYCLOAK_REDIRECT_URI}?returnTo=${pathname}${search}`,
      });
      return;
    }

    setIsChecking(false);
  };

  useEffect(() => {
    checkPermissions();
  }, [isAuthenticated, isLoading]);

  if (isChecking) {
    return <SplashScreen />;
  }

  if (!user) {
    return <SplashScreen />
  }

  return <>{children}</>;
}
