import { createApi } from "@reduxjs/toolkit/query/react";

import { authQuery } from "./baseQuery";
import type { TableResponse } from "src/types/common";
import { IBaseInvoice, IInvoiceStatistics, ISingleInvoiceResponse } from "src/types/invoices";

export const invoicesAPISlice = createApi({
  reducerPath: "presentation-service-invoices-api",
  tagTypes: ["Invoices"],
  baseQuery: authQuery,
  endpoints: (builder) => ({

    getInvoices: builder.query<TableResponse<IBaseInvoice>, void>({
      query: () => "/invoices?page=1&limit=5000",
      transformResponse: (res: TableResponse<IBaseInvoice>) => {
        res.rows = res.rows.map((x: any) => parseBaseInvoice(x));

        return res;
      },
    }),

    getInvoiceById: builder.query<ISingleInvoiceResponse, { id: string, customerId: string }>({
      query: ({ id, customerId }) => `/invoices/${id}?customerId=${customerId}`,
      transformResponse: (res: any) => {
        res = {
          ...res,
          status: res.status,
          stage: res.stage,
          paymentStatus: res.paymentStatus,
          totalRemainingBalance: parseFloat(res.totalRemainingBalance),
          totalAmount: parseFloat(res.totalAmount),
        };

        return res;
      },
    }),

    getInvoiceStatistics: builder.query<IInvoiceStatistics, void>({
      query: (id) => `/invoices/statistics`,
    }),

    searchInvoices: builder.query<TableResponse<IBaseInvoice>, string>({
      query: (url) => "/invoices/search" + url,
      transformResponse: (res: TableResponse<IBaseInvoice>) => {
        res.rows = res.rows.map((x: any) => parseBaseInvoice(x));

        return res;
      },
    }),
  }),
});

export const {
  useLazySearchInvoicesQuery,
  useGetInvoiceByIdQuery,
  useGetInvoicesQuery,
  useGetInvoiceStatisticsQuery,
} = invoicesAPISlice;

export const parseBaseInvoice = (x: any): IBaseInvoice => {
  return {
    id: x.id,
    invoiceNo: x.invoiceNo,
    dueDate: x.dueDate,
    countryCode: x.countryCode,
    currency: x.currency,
    paymentReference: x.paymentReference,
    status: x.status,
    stage: x.stage,
    paymentStatus: x.paymentStatus,
    customer: {
      id: x.customerId,
      customerNo: x.customerNo,
      customerName: x.customerName,
    },
    client: {
      id: x.clientId,
      name: x.clientName,
      logo_uri: x.clientLogo,
    },
    totalRemainingBalance: parseFloat(x.totalRemainingBalance),
    totalAmount: parseFloat(x.totalAmount),
  }
}