import type { Theme, SxProps } from '@mui/material/styles';
import type { ButtonBaseProps } from '@mui/material/ButtonBase';

import { useState, useCallback, useEffect } from 'react';
import { usePopover } from 'minimal-shared/hooks';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ButtonBase from '@mui/material/ButtonBase';

import { Iconify } from 'src/components/iconify';
import { CustomPopover } from 'src/components/custom-popover';
import { useGetClientQuery, useGetClientsQuery } from 'src/store/slices/clientsAPISlice';
import type { IClient } from 'src/types/clients';
import { Scrollbar } from 'src/components/scrollbar';
import { appStorage } from 'src/lib/localStorage';
import { CONFIG } from 'src/global-config';
import { CircularProgress } from '@mui/material';
import { useAuthContext } from 'src/auth/hooks';
import inioLogo from 'src/assets/logo/inio-signature.svg'
import { useTranslate } from 'src/locales';

export type WorkspacesPopoverProps = ButtonBaseProps;

export function WorkspacesPopover({ sx, ...other }: WorkspacesPopoverProps) {
  const { user } = useAuthContext();
  const { data: client, isLoading } = useGetClientQuery(user.profile.clientId);

  return (
    <>
      {isLoading && <CircularProgress size={24} color="primary" />}

      {!isLoading && (
        <Box
          component="div"
          sx={[
            {
              display: 'flex',
              alignItems: 'center',
              py: 0.5,
              gap: { xs: 0.5, ['sm']: 1 },
            },
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
          {...other}
        >
          <Avatar
            alt={client?.data.name}
            src={client?.data.logo_uri || ''}
            sx={{ width: 24, height: 24 }}
          />
          <Box
            component="span"
            sx={{ typography: 'subtitle2', display: { xs: 'none', ['sm']: 'inline-flex' } }}
          >
            {client?.data.name}
          </Box>
        </Box>
      )}
    </>
  );
}

export function WorkspacesPopoverSA({ sx, ...other }: WorkspacesPopoverProps) {
  const { t } = useTranslate()
  const mediaQuery = 'sm';
  const { data: clientsData, isLoading } = useGetClientsQuery(['id', 'name', 'logo_uri']);

  const data = (clientsData && clientsData.data) || [];

  const { open, anchorEl, onClose, onOpen } = usePopover();
  const [workspace, setWorkspace] = useState<IClient | null>(null);

  const handleChangeWorkspace = useCallback(
    (newValue: any) => {
      setWorkspace(newValue);
      appStorage.setItem(CONFIG.WORKSPACE_LOCAL_STORAGE_KEY, newValue.id);
      onClose();
      window.location.reload();
    },
    [onClose]
  );

  const clearWorkspace = useCallback(() => {
    setWorkspace(null);
    appStorage.removeItem(CONFIG.WORKSPACE_LOCAL_STORAGE_KEY)
    onClose();
    window.location.reload();
  },
    [onClose]
  );

  useEffect(() => {
    if (data && data?.length && appStorage.getItem(CONFIG.WORKSPACE_LOCAL_STORAGE_KEY)) {
      setWorkspace(
        data.find((x) => x.id == appStorage.getItem(CONFIG.WORKSPACE_LOCAL_STORAGE_KEY)) || data[0]
      );
    }
  }, [data, setWorkspace]);

  const buttonBg: SxProps<Theme> = {
    height: 1,
    zIndex: -1,
    opacity: 0,
    content: "''",
    borderRadius: 1,
    position: 'absolute',
    visibility: 'hidden',
    bgcolor: 'action.hover',
    width: 'calc(100% + 8px)',
    transition: (theme) =>
      theme.transitions.create(['opacity', 'visibility'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shorter,
      }),
    ...(open && {
      opacity: 1,
      visibility: 'visible',
    }),
  };

  const renderButton = () => (
    <ButtonBase
      disableRipple
      onClick={onOpen}
      sx={[
        (theme) => ({
          py: 0.5,
          gap: { xs: 0.5, [mediaQuery]: 1 },
          '&::before': buttonBg,
          borderColor: "#919EAB40",
          borderWidth: 1,
          borderStyle: "solid",
          borderRadius: 1,
          px: 1
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...other}
    >
      {isLoading && <CircularProgress size={24} color="primary" />}

      {workspace && (
        <>
          <Avatar
            alt={workspace?.name}
            src={workspace?.logo_uri || ''}
            sx={{ width: 24, height: 24 }}
          />

          <Box
            component="span"
            sx={{ typography: 'subtitle2', display: { xs: 'none', [mediaQuery]: 'inline-flex' } }}
          >
            {`${workspace?.name.slice(0, 30).trim()}${workspace?.name.length > 30 ? '...' : ''}`}
          </Box>

          <Iconify width={16} icon="carbon:chevron-sort" sx={{ color: 'text.disabled' }} />
        </>
      )}

      {!workspace && !isLoading && (
        <>

          <Avatar
            alt="Inio"
            src={inioLogo}
            sx={{ width: 24, height: 24 }}
          />

          <Box
            component="span"
            sx={{ typography: 'subtitle2', display: { xs: 'none', [mediaQuery]: 'inline-flex' } }}
          >
            {t("common.allClients")}
          </Box>


          <Iconify width={16} icon="carbon:chevron-sort" sx={{ color: 'text.disabled' }} />
        </>
      )}
    </ButtonBase>
  );

  const renderMenuList = () => (
    <CustomPopover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      slotProps={{
        arrow: { placement: 'top-left' },
        paper: { sx: { mt: 0.5, ml: -1.55 } },
      }}
    >
      <MenuList sx={{ width: 350 }}>
        <Scrollbar sx={{ maxHeight: 350 }}>
          <MenuItem
            selected={!!workspace}
            onClick={clearWorkspace}
            sx={{ height: 48 }}
          >
            <Avatar
              alt="Inio"
              src={inioLogo}
              sx={{ width: 24, height: 24 }}
            />

            <Box component="span" sx={{ flexGrow: 1, fontWeight: 'fontWeightMedium' }}>
              {t("common.allClients")}
            </Box>
          </MenuItem>

          {data.length &&
            data.map((option) => (
              <MenuItem
                key={option.id}
                selected={option.id === workspace?.id}
                onClick={() => handleChangeWorkspace(option)}
                sx={{ height: 48 }}
              >
                <Avatar
                  alt={option.name}
                  src={option.logo_uri || ''}
                  sx={{ width: 24, height: 24 }}
                />

                <Box component="span" sx={{ flexGrow: 1, fontWeight: 'fontWeightMedium' }}>
                  {option.name.length > 20 ? `${option.name.slice(0, 20)}...` : option.name}
                </Box>
              </MenuItem>
            ))}
        </Scrollbar>
      </MenuList>
    </CustomPopover>
  );

  return (
    <>
      {renderButton()}
      {renderMenuList()}
    </>
  );
}
