import { createApi } from "@reduxjs/toolkit/query/react";

import { authQuery } from "./baseQuery";
import type { IUser } from "src/types/users";

export const usersAPISlice = createApi({
  reducerPath: "presentation-service-members-api",
  tagTypes: ["Users"],
  baseQuery: authQuery,
  endpoints: (builder) => ({
    
    getUsers: builder.query<IUser[], void>({
      query: () => "/members",
      providesTags: ["Users"],
    }),

    getUser: builder.query<IUser, string>({
      query: (id) => `/members/${id}`,
      providesTags: ["Users"],
    }),

    createUser: builder.mutation({
      query: (body) => ({
        url: "/members",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Users"],
    }),

    editUser: builder.mutation({
      query: (body) => ({
        url: `/members/${body.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Users"],
    }),

    deleteUser: builder.mutation({
      query: (id) => ({
        url: `/members/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Users"],
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetUsersQuery,
  useCreateUserMutation,
  useDeleteUserMutation,
  useEditUserMutation,
} = usersAPISlice;
