import 'src/global.css';

import { useEffect } from 'react';

import { usePathname } from 'src/routes/hooks';

import { themeConfig, ThemeProvider } from 'src/theme';
import { I18nProvider } from 'src/locales/i18n-provider';

import { ProgressBar } from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import { SettingsDrawer, defaultSettings, SettingsProvider } from 'src/components/settings';

import { AuthProvider } from 'react-oidc-context';
import { LocalizationProvider } from './locales';
import { Snackbar } from 'src/components/snackbar/snackbar';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { UserManager } from 'oidc-client-ts';

// ----------------------------------------------------------------------

type AppProps = {
  children: React.ReactNode;
};

const oidcConfig = {
  authority: `${import.meta.env.VITE_KEYCLOAK_AUTHORITY}/${import.meta.env.VITE_KEYCLOAK_REALM}`,
  client_id: import.meta.env.VITE_KEYCLOAK_CLIENT,
  redirect_uri: import.meta.env.VITE_KEYCLOAK_REDIRECT_URI,
  response_type: 'code',
  scope: 'openid profile email',
  automaticSilentRenew: true,
};

export const userManager = new UserManager(oidcConfig);

export default function App({ children }: AppProps) {
  useScrollToTop();

  return (
    <I18nProvider>
      <Provider store={store}>
        <AuthProvider {...oidcConfig}>
          <SettingsProvider defaultSettings={defaultSettings}>
            <LocalizationProvider>
              <ThemeProvider
                noSsr
                defaultMode={themeConfig.defaultMode}
                modeStorageKey={themeConfig.modeStorageKey}
              >
                <MotionLazy>
                  <Snackbar />
                  <ProgressBar />
                  <SettingsDrawer defaultSettings={defaultSettings} />
                  {children}
                </MotionLazy>
              </ThemeProvider>
            </LocalizationProvider>
          </SettingsProvider>
        </AuthProvider>
      </Provider>
    </I18nProvider>
  );
}

// ----------------------------------------------------------------------

function useScrollToTop() {
  const pathname = usePathname();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
