import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CONFIG } from 'src/global-config';
import { userManager } from 'src/app';
import { appStorage } from 'src/lib/localStorage';

const storageKey = `oidc.user:${import.meta.env.VITE_KEYCLOAK_AUTHORITY}/${import.meta.env.VITE_KEYCLOAK_REALM}:${import.meta.env.VITE_KEYCLOAK_CLIENT}`;

export const baseQuery = (baseUrl: string) =>
  fetchBaseQuery({
    baseUrl: baseUrl,
    credentials: 'include',
    prepareHeaders: (headers) => {
      const clientId = getCurrentClientId();

      const token = JSON.parse(sessionStorage.getItem(storageKey) ?? '{}').access_token;

      headers.set('Authorization', `Bearer ${token}`);

      if (clientId) {
        headers.set('X-Client-Id', clientId);
      }

      return headers;
    },
  });

export const authQuery = async (args: any, api: any, extraOptions: any) => {
  const baseQueryWithDynamicUrl = baseQuery(
    extraOptions?.baseUrl || import.meta.env.VITE_API_PRESENTATION_SERVICE
  );

  const result = await baseQueryWithDynamicUrl(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    await userManager.signoutRedirect();
  }

  return result;
};

export const getCurrentClientId = () => {
  const clientId = JSON.parse(sessionStorage.getItem(storageKey) ?? '{}').profile?.clientId;
  const workspaceClientId = appStorage.getItem(CONFIG.WORKSPACE_LOCAL_STORAGE_KEY) as string;

  if (clientId) {
    return clientId;
  } else if (workspaceClientId) {
    // Don't merge to one IF. Fixes a case where a user is logged in after operator. Non operators always have clientId.
    return workspaceClientId;
  }

  return null;
};
