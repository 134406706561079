import type { ButtonProps } from '@mui/material/Button';

import { useCallback } from 'react';

import Button from '@mui/material/Button';

import { useRouter } from 'src/routes/hooks';

import { useTranslate } from 'src/locales';
import { useAuth } from 'react-oidc-context';

// ----------------------------------------------------------------------

type Props = ButtonProps & {
  onClose?: () => void;
};

export function SignOutButton({ onClose, sx, ...other }: Props) {
  const { t } = useTranslate();
  const { signoutRedirect } = useAuth();
  const router = useRouter();

  const handleLogout = useCallback(async () => {
    try {
      await signoutRedirect();
    } catch (error) {
      console.error(error);
    }
  }, [onClose, router]);

  return (
    <Button
      fullWidth
      variant="soft"
      size="large"
      color="error"
      onClick={handleLogout}
      sx={sx}
      {...other}
    >
      {t('buttons.logout')}
    </Button>
  );
}
